<template>
  <main class="content-container-manual">
    <div
      v-if="isDebug"
      class="error-debug"
    >
      <pre>{{ error }}</pre>
    </div>

    <div class="error">
      <img
        v-if="isMainPage"
        src="@/assets/logo-maco-outline.svg?url"
        class="img-rwd error__background-image"
        alt=""
      />

      <div
        v-if="error.statusCode === 404"
        class="error__content"
      >
        <LogoSmall class="error__logo" />

        <h3 class="error__header">
          {{ translation.header }}
        </h3>

        <p
          class="error__text"
          v-html="replaceLinkText(translation.text, translation.linkArray)"
        />

        <h1 class="error__status-code">
          404
        </h1>
      </div>

      <template v-else>
        <h1 class="error__status-code">
          {{ translation.error }} {{ error.statusCode }}
        </h1>
      </template>
    </div>
  </main>
</template>

<script setup lang="ts">
import type { BaseErrorPageProps } from '~ui/types'

defineProps<BaseErrorPageProps>()

const replaceLinkText = (
  string: string,
  linkArray: Array<{ label: string, href: string }>
) => {
  let index = 0

  return string.replace(/\{{.+?\}}/g, () => {
    if (index >= linkArray.length) return ''

    const { href, label } = linkArray[index++]
    return `<a href="${href}">${label}</a>`
  })
}
</script>

<style lang="scss">
.error {
  width: 100%;
  height: calc(100vh - var(--content-offset, rem(0px)));
  height: calc(100svh - var(--content-offset, rem(0px)));
  position: relative;
  display: grid;
  place-items: center;
  gap: rem(16px);
  text-align: center;
  isolation: isolate;

  &__background-image {
    position: absolute;
    z-index: z-index(below);
    width: 60%;
    height: 60%;
  }

  &__content {
    display: grid;
    place-items: center;
    gap: rem(16px);
  }

  &__logo {
    margin-bottom: rem(16px);
  }

  &__header {
    font-size: rem(22px);
    font-weight: 600;
  }

  &__text {
    font-size: rem(16px);

    & a {
      font-weight: 600;
      text-decoration: underline;
    }
  }

  &__status-code {
    color: color(primary);
    font-size: rem(60px);
    margin-bottom: 0;
    font-weight: 600;

    @include media-query(md) {
      font-size: rem(120px);
    }
  }
}

.error-debug pre {
  overflow: auto;
  border: 1px solid color(primary);
  padding: em($container-padding);
}
</style>
